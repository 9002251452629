import { Avatar, Button, Card, Chip, Typography } from "@mui/joy"
import { useEffect, useState } from "react"
import './Admin.css'
import { ConfigProvider, Spin } from "antd"

import Discord from './assets/discord.svg'
import LanguageIcon from '@mui/icons-material/Language'

import { message } from 'antd';
import AddProject from "./Components/AddProject"

import { useLocation } from 'react-router';


export default function AdminPage({ location }) {


    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [messageApi, contextHolder] = message.useMessage();
    const [open, setOpen] = useState(false);
    const params = new URLSearchParams(useLocation().search)
    const [status, setStatus] = useState('Add')
    const [afterWork, setAfterWork] = useState(false)

    const [formData, setFormData] = useState({
        name: '',
        icon: '',
        twitter: '',
        web: '',
        categories: [],
        description: '',
        votes: 0
    });

    useEffect(() => {
        fetch('https://chetan-ecosystem-api-hyper-creations.vercel.app/api/get').then(r => r.json()).then(r => {
            setTimeout(() => {
                setData(r)
                setLoading(false)
            }, 200);
        })
    }, [])

    const LoadingElement = () => <Spin />

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', position: 'absolute' }}>
            <LoadingElement />
        </div>
    }

    const key = 'updatable';
    const openMessage = (e) => {
        messageApi.open({
            key,
            type: 'loading',
            content: 'Working...',
        });
        setTimeout(() => {
            messageApi.open({
                key,
                type: 'success',
                content: e + '!',
                duration: 2,
            });
        }, 1000);
    };


    const addProject = (d) => {
        let newD = structuredClone(data)
        newD.push(d)
        setData(newD)
    }

    console.log(params)

    if (params.get('pass') != '679385') {
        return <Typography sx={{ padding: '40px', color: 'red' }} level='h1'>NOT FOR YOU!</Typography>
    }

    return <div style={{ padding: '42px' }}>
        {contextHolder}
        <AddProject open={open} setOpen={setOpen} add={addProject} msg={messageApi} status={status} formData={formData} setFormData={setFormData} afterWork={afterWork} />
        <div style={{ display: 'flex', gap: '20px', marginBottom: '20px', zIndex: -9 }}>
            <Typography level='h3'>Projects</Typography>
            <Button onClick={() => {
                setStatus('Add')
                setAfterWork(false)
                setOpen(true)
                setFormData({
                    name: '',
                    icon: '',
                    twitter: '',
                    web: '',
                    categories: [],
                    description: '',
                    votes: 0
                })
                }}>Add Project</Button>

        </div>
        <div style={{ display: 'flex', gap: '30px', flexDirection: 'column-reverse', zIndex: -9 }}>
            {
                data.map(r => {
                    return <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}><Card sx={{ background: '#FEF7FF' }}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Avatar src={r.icon} />
                            <Typography level="title-lg">{r.name}</Typography>
                        </div>
                        <Typography level="title-sm">{r.description}</Typography>
                        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                            {
                                r.categories.map(o => <Chip variant="plain">{o}</Chip>)
                            }
                        </div>

                        <Typography sx={{ wordBreak: 'break-all', ":hover": { cursor: 'pointer', opacity: 0.7 } }} fontWeight='bold' startDecorator={<img src={Discord} style={{ filter: 'invert()' }} />} onClick={() => window.open(r.twitter)}>{r.twitter}</Typography>
                        <Typography sx={{ wordBreak: 'break-all', ":hover": { cursor: 'pointer', opacity: 0.7 } }} fontWeight='bold' startDecorator={<LanguageIcon />} onClick={() => window.open(r.web)}>{r.web}</Typography>
                    </Card>
                        <div style={{display: 'flex', gap: '10px'}}>
                            <Button color='danger' onClick={() => {
                                messageApi.open({
                                    type: 'loading',
                                    content: 'Removing Project..',
                                    duration: 0.5
                                });
                                fetch('https://chetan-ecosystem-api-hyper-creations.vercel.app/api/remove/', {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        id: r.id
                                    }),
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                }).then(o => {
                                    if (o.status != 200) {
                                        setTimeout(() => {
                                            messageApi.error('An error occurred!', 2);
                                        }, 500);
                                    } else {
                                        setTimeout(() => {
                                            messageApi.open({
                                                type: 'success',
                                                content: 'Removed Project!',
                                                duration: 2
                                            });
                                            setOpen(false)
                                            let newD = structuredClone(data)
                                            newD = newD.filter(z => z.name != r.name)
                                            setData(newD)
                                        }, 500);
                                    }
                                })
                            }}>Remove</Button>
                            <Button color='success' onClick={() => {
                                setStatus("Edit")
                                setFormData(r)
                                setOpen(true)
                                setAfterWork(true)
                            }}>Edit</Button>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
} 