import { Typography, Card, Avatar, Chip, Box, Button } from '@mui/joy';
import ReactCardFlip from 'react-card-flip';
import { useEffect, useState } from 'react';
import twitter from '../assets/twitter.svg'
import upvote from '../assets/upvote.svg'
// import web from '../assets/web.svg'
import LanguageIcon from '@mui/icons-material/Language';

export default function CatCard({r, updateVote, flips, handleFlip}) {

    // const [isFlipped, setIsFlipped] = useState(false);
    const [votes, setVotes] = useState(r.votes)
    const [canVote, setCanVote] = useState(false)
    const [classname, setClassname] = useState('cat-card')

    useEffect(() => {
        let hasVoted = localStorage.getItem(r.name.replaceAll(' ', '_').toLowerCase()+'_vote');
        if (hasVoted) {
            setCanVote(false)
        } else {
            setCanVote(true)
        }
    }, [])

    const handleClick = (e) => {
        e.preventDefault();
        
        handleFlip(r.name)
        // setIsFlipped(!isFlipped)
        if (flips[r.name]) {
            setClassname('cat-card')
        } else {
            setClassname('cat-card open')
        }
    };  


    return <Box className={flips[r.name] ? 'cat-card open' : 'cat-card'}>
        <ReactCardFlip isFlipped={flips[r.name]} flipDirection="horizontal">
        <Card sx={{minHeight: 238 || '320px', maxWidth: '16rem', width: '210px', background: '#A339FF' || 'rgba(255,255,255, 0.1)', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', boxShadow: '0' || '0px 1px 20px 0px rgba(125,43,196,1)', borderRadius: '10px', ":hover": {
            cursor: 'pointer'
        }}} onClick={handleClick}>
        <Avatar sx={{height: '120px', width: '120px', marginBottom: '20px', boxShadow: '0' || 'inset 1px 5px 12px rgba(0,0,0,.435) !important', background: '#603A80'}} src={r.icon} />
        <Typography level='h3' sx={{color: 'white', textAlign: 'center'}}>{r.name}</Typography>
        <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
            {
            r.categories.map(o => <Chip size='sm' sx={{borderRadius: '6px', backgroundColor: '#603A80' || 'rgba(255,255,255, 0.1)', color: 'white', boxShadow: '0 1px 5px #603a80'}}>{o}</Chip>)
            }
        </div>
    </Card>

    <Card style={{minHeight: '238px'}} sx={{minHeight: '238px' || '320px', maxWidth: '16rem', width: '210px', background: '#A339FF' || 'rgba(255,255,255, 0.1)', display: 'flex', justifyContent: 'space-between', boxShadow: '0' || '0px 1px 20px 0px rgba(125,43,196,1)', borderRadius: '10px', alignItems: 'center'}} 
    >
        <Typography style={{color: 'white', opacity: 0.7}} level='h6' mt={'18px'}>{r.description}
        <div style={{position: 'absolute', width: '100%', height: '100%', top: 0}} onClick={handleClick}></div>
    </Typography>
        <div style={{display: 'flex', gap: '10px', alignItems: 'center', marginBlock: '18px'}}>
            <Box component="img" src={twitter} style={{height: '22px', zIndex: 1}} sx={{":hover": {cursor: 'pointer'}}} onClick={() => window.open(r.twitter)} />
            <LanguageIcon style={{transform: 'scale(1.1)', color: 'white'}} sx={{":hover": {cursor: 'pointer'}}} onClick={() => window.open(r.web)} />
            <Button variant='plain' sx={{background: 'rgba(255,255,255,0.2)', borderRadius: '50px', ":hover": {
                background: canVote ? 'rgba(255,255,255,0.15)' : 'rgba(255,255,255,0.2)',
                cursor: canVote ? 'pointer' : 'default'
            }, ":active": {
                background: canVote ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0.2)'
            }}} onClick={() => {
                if (canVote) {
                    setCanVote(false)
                    setVotes(votes+1)
                    updateVote(r.name)
                    localStorage.setItem(r.name.replaceAll(' ', '_').toLowerCase()+'_vote', true)
                    fetch('https://chetan-ecosystem-api-hyper-creations.vercel.app/api/vote/', {
                        method: 'POST',
                        body: JSON.stringify({
                            name: r.name
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                }
            }}>
                <Box component="img" src={upvote} style={{height: '18px', marginRight: '6px'}} />
                <Typography sx={{color: 'white'}}>{votes}</Typography>
            </Button>
        </div>
    </Card>
    </ReactCardFlip>
    </Box>
}