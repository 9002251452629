import * as React from 'react';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import { Typography, Box, List, ListItem, Checkbox, Select, Option, Button } from '@mui/joy/'
import { Card } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import { message } from 'antd';

export default function AddProject({ setOpen, open, add, msg, status, formData, setFormData, afterWork }) {

    const [messageApi, contextHolder] = message.useMessage();

    const handleInputChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };


    const handleCheckboxChange = (value) => {
        const updatedServices = formData.categories.includes(value)
            ? formData.categories.filter((service) => service !== value)
            : [...formData.categories, value];

        handleInputChange('categories', updatedServices);
    };

    const handleSubmit = () => {
        messageApi.open({
            type: 'loading',
            content: status+'ing Project..',
            duration: 0.5
        });
        fetch('https://chetan-ecosystem-api-hyper-creations.vercel.app/api/add', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => {
            if (r.status == 400) {
                setTimeout(() => {
                    messageApi.error('An error occurred!', 2);
                }, 500);
            } else {
                setTimeout(() => {
                    messageApi.open({
                        type: 'success',
                        content: status+'ed Project!',
                        duration: 2
                    });
                    setOpen(false)
                    if (status == 'Add') {
                        add(formData)
                    }
                    if (afterWork) {
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000);
                    }
                    setFormData({
                        name: '',
                        icon: '',
                        twitter: '',
                        web: '',
                        categories: [],
                        description: ''
                    })
                }, 500);
            }
        })
    };


    return (

        <React.Fragment>
        {contextHolder}
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1 }}
      >
<Card style={{ width: '300px', background: 'white', borderRadius: '10px', padding: '20px', height: '90%', overflowY: 'scroll' }} className='hide-scroll'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography level='title-lg'>{status} Project</Typography>
                <ModalClose />
            </div>
            <FormLabel>Project Name</FormLabel>
            <Input placeholder="" value={formData.name} onChange={(e) => handleInputChange('name', e.target.value)} />
            <FormLabel>Icon</FormLabel>
            <Input placeholder="" value={formData.icon} onChange={(e) => handleInputChange('icon', e.target.value)} />
            <FormLabel>Twitter</FormLabel>
            <Input placeholder="" value={formData.twitter} onChange={(e) => handleInputChange('twitter', e.target.value)} />
            <FormLabel>Web</FormLabel>
            <Input placeholder="" value={formData.web} onChange={(e) => handleInputChange('web', e.target.value)} />
            <FormLabel>Description</FormLabel>
            <Input placeholder="" value={formData.description} onChange={(e) => handleInputChange('description', e.target.value)} />
            <FormLabel>Categories</FormLabel>
            <Box role="group" aria-labelledby="sandwich-group">
                <List size="sm">
                    {['NFT',
'DeFi',
'Wallet',
'Games',
'Social',
'Tools',
'Infrastructure',
'Explorers',
'Bridges',
'Marketplaces','Memecoins'].map(r => (
                        <ListItem key={r}>
                            <Checkbox size='sm' label={r} checked={formData.categories.includes(r)} onChange={() => handleCheckboxChange(r)} />
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Button color='danger' sx={{ marginTop: '20px', width: '100%' }} onClick={handleSubmit}>Submit</Button>
        </Card>
      </Modal>
    </React.Fragment>
  );

        
    }