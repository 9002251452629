import { useState } from 'react';
import useWindowDimensions from '../Modules/useWindowDimensions';
import testLogo from '../assets/test-logo.svg'
import twitter from '../assets/twitter.svg'
import { Button } from '@mui/joy';
import { useEffect } from 'react';

function Menu() {

    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
  
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();

    
  useEffect(() => {
    setWidth(windowWidth);
    setHeight(windowHeight);
    }, [windowWidth, windowHeight]);

  return <div id="menu" style={{paddingInline: (width < 670) ? '20px' : '99px'}}>
    <img style={{width: '196px'}} src="https://res.cloudinary.com/dvwrpep92/image/upload/v1708952644/ieh0ordown2epxd9txsv.webp" onClick={() => window.open('/', '_self')} />
    <Button variant='plain' onClick={() => window.open('https://twitter.com/AptoRobos')} color='neutral' sx={{":hover": {
        backgroundColor: 'transparent',
        opacity: 0.8
    }, ":active": {
        opacity: 0.7
    }}}>
        <img src={twitter} style={{height: '22px'}} />
    </Button>
  </div>
}

export default Menu;