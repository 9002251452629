import React, { useState, useEffect } from 'react';
import { Button, ConfigProvider, notification } from 'antd';

const DisclaimerBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

const handleAcknowledge = () => {
    notification.destroy()
    localStorage.setItem('acknowledgedDisclaimer', 'true');
};

useEffect(() => {
      const hasAcknowledgedDisclaimer = localStorage.getItem('acknowledgedDisclaimer');
    if (!hasAcknowledgedDisclaimer) {
        notification.destroy()
      notification.open({
        placement: 'bottomRight',
        description:
          'The projects and products in the Aptos Ecosystem are listed based on the community requests and the requests sent by the projects. This is not an official endorsement from Aptos Foundation / Aptos Labs, and there is no control over any of the projects listed here by AptoRobos, the developers of this website. We are not responsible for any damages or losses that result from the use of, or inability to use these projects. This list is crowdsourced and there is no official tie-up with any projects in the list.',
        duration: 0,
        btn: (
          <ConfigProvider theme={{
            "token": {
              "colorPrimary": "#b517ff",
              "colorInfo": "#b517ff"
            }}}>
            <Button type="primary" style={{borderRadius: '50px', width: '100%', float: 'none'}} onClick={handleAcknowledge} className='remove-float'>
              Got It
            </Button>
          </ConfigProvider>
        ),
      });
    }
  }, []);

  return null;
};

export default DisclaimerBanner;