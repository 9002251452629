import { Select, ConfigProvider, Space } from 'antd';
import { Typography, Card, Avatar, Chip, Button } from '@mui/joy';

export default function SelectMenu({handleChange, items, loading}) {
    return <div style={{width: '100%', marginBottom: '40px'}}>

    <Typography style={{color: 'white', opacity: 0.7}} level='body-xs' fontWeight={500} mb={1}>Filter projects by category</Typography>
            <ConfigProvider
              theme={{
                "token": {
                  "colorPrimary": "#b517ff",
                  "colorInfo": "#b517ff"
                },
                "components": {
                  "Select": {
                    "colorText": "rgba(255, 255, 255)",
                    "colorPrimary": "#603A80",
                    "colorPrimaryHover": "#603A80",
                    "colorBgElevated": "#A339FF",
                    "controlItemBgActive": "#8334c7",
                    "colorFillSecondary": "rgba(255, 255, 255)",
                    "colorBorder": "#603A80",
                    "colorBgContainer": "#A339FF",
                    "colorTextPlaceholder": "white",
                    "controlHeight": 40,
                  }
                }
              }}
            >
              <Select
              disabled={loading}
              placeholder="All"
                style={{
                  width: '100%',
                }}
                onChange={handleChange}
                options={items.map(r => {
                    return {
                        value: r,
                        label: r
                    }
                })}
              />
            </ConfigProvider>
    </div>
}