import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { Button } from '@mui/joy'

export default function CategoryChips({list, change, loading}) {
  const [selected, setSelected] = React.useState('');

  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }} mt={6} mb={6}>
        {list.map(r => {
            return <Button disabled={loading} onClick={() => change(r)} sx={{borderRadius: '50px', background: '#A339FF', boxShadow: '0.00px 1.00px 20px 0px rgba(125,43,196,1)', transition: 'all 0.2s', ":hover": {background: '#a339ff87'}, ":active": {
                background: '#a339ff70'
            }, ":disabled": {
              background: '#a339ff70',
              opacity: 0.5
            }}}>{r}</Button>
        })}
        {/* <RadioGroup
          name="best-movie"
          aria-labelledby="best-movie"
          orientation="horizontal"
          sx={{ flexWrap: 'wrap', gap: 1, justifyContent: 'center', width: '100%' }}
        >
          {list.map((name) => {
            const checked = selected === name;
            return (
              <Chip
                key={name}
                variant="plain"
                color={checked ? 'solid' : 'neutral'}
                sx={{
                    background: checked ? 'rgba(255,255,255,0.1)' : 'transparent',
                    borderRadius: '6px',
                    ":hover": {
                        background: checked ? 'rgba(255,255,255,0.1)' : 'transparent'
                    }
                }}
              >
                <Radio
                  variant="soft"
                  color={checked ? 'primary' : 'neutral'}
                  id='radio-cat'
                  disableIcon
                  overlay
                  label={name}
                  value={name}
                  sx={{color: 'white', opacity: checked ? 1 : 0.6, ":hover": {
                    opacity: 1,
                    background: 'transparent'
                  }}}
                  checked={checked}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setSelected(name);
                    }
                  }}
                />
              </Chip>
            );
          })}
        </RadioGroup> */}
    </Box>
  );
}