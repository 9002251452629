import './App.css';
import { Typography, Card, Avatar, Chip, Button, Link } from '@mui/joy';

import Menu from './Components/Menu';
import CategoryChips from './Components/Categories';

import ReactCardFlip from 'react-card-flip';
import CatCard from './Components/CatCard';
import Telegram from './assets/telegram.svg'
import Discord from './assets/discord.svg'
import Twitter from './assets/twitter.svg'
import useWindowDimensions from './Modules/useWindowDimensions'
import { useState } from 'react';
import { useEffect } from 'react';

import FormItemLabel from 'antd/es/form/FormItemLabel';
import SelectMenu from './Components/SelectMenu';
import { ConfigProvider, Spin } from 'antd';
import DisclaimerBanner from './Components/Disclaimer';

function App() {

    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const [data, setData] = useState([])

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    const [category, setCategory] = useState('All')

    const [loading, setLoading] = useState(true)

    const [isFlips, setIsFlips] = useState({})
    

    useEffect(() => {
        setWidth(windowWidth);
        setHeight(windowHeight);
    }, [windowWidth, windowHeight]);

    const Categories = [
        'All',
        'NFT',
        'DeFi',
        'Wallet',
        'Games',
        'Social',
        'Tools',
        'Infrastructure',
        'Explorers',
        'Bridges',
        'Marketplaces',
        'Memecoins'
    ]

    // const data = 

    useEffect(() => {
        fetch('https://chetan-ecosystem-api-hyper-creations.vercel.app/api/get').then(r => r.json()).then(r => {
            setTimeout(() => {
                r = randomize(r)
                setData(r)
                setLoading(false)
                // console.log(r)
                let d = {}
                r.forEach(o => {
                    d[o.name] = false
                })
                setIsFlips(d)
            }, 200);
        })
    }, [])

    function handleFlip(name) {
        let d = structuredClone(isFlips)
        d[name] = !d[name]
        setIsFlips(d)
        // console.log(isFlips, name)
    }

    function resetFlips() {
        let d = structuredClone(data)
        d.forEach(o => {
            d[o.name] = false
        })
        setIsFlips(d)
    }
    
    const handleChange = (value) => {
        resetFlips()
        setCategory(value)
    };

    function randomize(array) {
        const shuffledArray = [...array];
        
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        
        return shuffledArray;
    }
    
    // function randomize(seed, array) {
    //     function getRandom(seed) {
    //         const x = Math.sin(seed) * 10000;
    //         return x - Math.floor(x);
    //     }
    //     const shuffledArray = [...array];
    //     for (let i = shuffledArray.length - 1; i > 0; i--) {
    //         const j = Math.floor(getRandom(seed + i) * (i + 1));
    //         [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    //     }
    //     return shuffledArray;
    // }

    
    function sortByVotesDescending(array) {
        return array.slice().sort((a, b) => b.votes - a.votes);
    }
    const getData = () => {
        if (category == 'All') {
            return randomize(data)
        } else {
            // console.log(sortByVotesDescending(data.filter(r => r.categories.include(category))))
            return sortByVotesDescending(data.filter(r => r.categories.includes(category)))
        }
    }

    function updateVote(name) {
        let d = structuredClone(data)
        let up = data.filter(r => r.name == name)[0]
        up['votes'] += 1
        d[d.indexOf(d.filter(r => r.name == name)[0])] = up
        setData(d)
    }

    return <div style={{background: 'linear-gradient(45deg, #361355, #684187)'}}>
        <Menu />

        <div style={{ paddingInline: (width > 760) ? '99px' : '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
            <div style={{position: 'absolute', height: '100%', width: '100%'}} onClick={resetFlips}></div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '80px', gap: '15px', marginBottom: '40px' }}>
                <Typography sx={{ color: 'white', fontSize: Math.max(22, width * 0.05), textAlign: 'center' }} level='h2'>Explore the Aptos Ecosystem</Typography>
                <Typography sx={{ color: 'white', opacity: 0.7, fontWeight: 500, textAlign: 'center', fontSize: Math.max(16, width * 0.02) }} level='h4'>Learn what Aptos has for consumers. The complete overview of Aptos ecosystem.</Typography>
                {/* <Link href='https://basics.aptofolio.com' sx={{color: 'white', textDecoration: 'underline'}}>New to Aptos? Learn the Basics</Link> */}
            </div>
            {
                (width < 770) ?
            <SelectMenu handleChange={handleChange} items={Categories} loading={loading} /> :
            <CategoryChips list={Categories} change={handleChange} loading={loading} />
            }
            {loading ? <ConfigProvider theme={{
                  "token": {
                    "colorPrimary": "#b517ff",
                    "colorInfo": "#b517ff"
                  }
            }}>
                <Spin />
            </ConfigProvider> :
            <div style={{
                display: 'grid',
                gridTemplateColumns: (width < 680) ? '1fr': ((width) < 950) ? '1fr 1fr' : ((width < 1150) ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'),
                gap: '41px',
                justifyItems: 'center',
                width: 'fit-content',
                marginTop: '40px'
            }}>
                {
                    // // data.filter(r => (category == 'All') ? true : (r.categories.includes(category) ? true : false)).map(r => {
                    // //     return <CatCard r={r} />
                    // // })
                    // getData().map(r => {
                    //     return <CatCard r={r} />
                    // })
                    ((category  == 'All') ? data : sortByVotesDescending(data.filter(r => r.categories.includes(category)))).map(r => <CatCard handleFlip={handleFlip} flips={isFlips} key={r.name} r={r} updateVote={updateVote} />)
                    }
            </div> }
            <Button size='lg' sx={{ background: '#A339FF', boxShadow: '0.00px 1.00px 20px 0px rgba(125,43,196,1)', marginTop: '56px', marginBottom: '56px', transition: 'all 0.2s', ":hover": { background: '#a339ff87' } }} onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfQPoB8Wp3HZG80CpOWtuzLXBASW9at_jJMPGVbhII90_hySg/viewform')}>Submit Your Project</Button>
            <Typography sx={{color: 'white', opacity: 0.5, marginBottom: '50px', textAlign: 'center'}}>AptoFolio represents a compilation of noteworthy projects and offerings within the Aptos blockchain ecosystem. Aptos, a Level 1 blockchain designed for continuous evolution and built using the Move programming language, holds substantial potential. This potential has manifested in various products spanning key categories such as DeFi, NFTs, Payments, Social interactions, and Gaming. The Aptos Network is on a promising trajectory to contribute to the creation of premier and expansive ventures within the Web3 domain. The following compilation highlights the operational initiatives currently active on the network. AptoFolio serves as a resource established by AptoRobos, a project with a focus on education, alpha sharing, and fostering a strong community presence within the Aptos blockchain ecosystem.</Typography>
            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', columnGap: '40px', rowGap: '10px', zIndex: 100 }}>
                <Typography sx={{ color: 'white', ":hover": { cursor: 'pointer', opacity: 0.7 } }} fontWeight='bold' startDecorator={<img src={Discord} />} onClick={() => window.open('https://discord.gg/yaVHrTg6EQ')}>Join AptoRobos on Discord</Typography>
                <Typography sx={{ color: 'white', ":hover": { cursor: 'pointer', opacity: 0.7 } }} fontWeight='bold' startDecorator={<img src={Twitter} style={{height: '16px' }} />} onClick={() => window.open('https://twitter.com/AptoRobos')}>Follow AptoRobos on Twitter</Typography>
                <Typography sx={{ color: 'white', ":hover": { cursor: 'pointer', opacity: 0.7 } }} fontWeight='bold' startDecorator={<img src={Twitter} style={{height: '16px' }} />} onClick={() => window.open('https://twitter.com/intent/tweet?text=Just%20checked%20Aptofolio.com%2C%20a%20wonderful%20directory%20of%20Aptos%20products%20and%20protocols.%20A%20one-stop%20page%20where%20you%20can%20find%20out%20everything%20that%20the%20Aptos%20ecosystem%20has%20to%20offer.%20Built%20by%20@AptoRobos%21')}>Tweet About AptoFolio</Typography>
            </div>
            <div style={{ display: 'flex', gap: '10px', marginTop: '50px', marginBottom: '50px' }}>
                <Typography sx={{ opacity: 0.7, color: 'white' }}>Made with ❤ by</Typography>
                <Typography fontWeight='bold' sx={{ color: 'white', zIndex: 100, ":hover": {
                    cursor: 'pointer'
                } }} onClick={() => window.open('https://www.aptorobos.com/')}>AptoRobos</Typography>
            </div>
        </div>
        <DisclaimerBanner />
    </div>
}

export default App;
